<template>
  <div>
    <button class="btn tgme_widget_login_button" @click="onClick">
      <i class="tgme_widget_login_button_icon"></i>Login <span dir="auto">telegram</span>
    </button>
    <transition name="fade">
      <UIGrecaptchaV2
          v-if="recaptchaV2"
          :callback="(e) => getSocialData(e)"
          @on-close="recaptchaV2 = false"
      />
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import UIGrecaptchaV2 from "@/components/UIGrecaptchaV2.vue";

export default {
  components: {UIGrecaptchaV2},
  data() {
    return {
      telegramData: false,
      recaptchaV2: false
    }
  },

  computed: {
    debugMode() {
      return !!localStorage.getItem('debug-tg')
    }
  },

  methods: {
    async getSocialData(captchaV2 = null) {

      const payload = {
        auth_data: this.telegramData,
        redirect_uri: this.$route.query.redirect_uri
      }

      if (!captchaV2) {
        if (localStorage.getItem('only-v2')) {
          console.log('recaptchaV2')
          this.recaptchaV2 = true
          return;
        } else {
          // eslint-disable-next-line no-undef
          payload.captcha = await grecaptcha.execute(process.env.RECAPTCHA_ID, {action: 'social_account_login'})
        }

      } else {
        payload.captcha2 = captchaV2
      }
      const config = {
        headers: {}
      }

      if (this.$route.query.platform) {
        config.headers.casino = this.$route.query.platform
      }

      if (this.debugMode) {
        console.log('payload', payload)
        console.log('redirect_uri', this.$route.query.redirect_uri)
      }

      axios.post(`/api/v1/user/telegram/socialaccount/login/`, payload, config)
          .then(({data}) => {
            console.log('social auth data', data)
            const redirect_uri = this.$route.query.redirect_uri

            if (this.debugMode) {
              console.log('data', data)
            }

            if (redirect_uri) {
              window.location.href = `${redirect_uri}?token=${data.token}&ussid=${data.ussid}&social=telegram`
            } else {
              console.log('No redirect url')
              window.close()
            }
          })
          .catch(e => {
            console.error(e)
            if (this.debugMode) {
              if (e?.response) {
                console.error('error status', e?.response?.status)
                console.error('error data', e?.response?.data)
              } else {
                console.error('error', e)
              }
            }

            const redirect_uri = this.$route.query.redirect_uri
            if (e?.response?.data?.code === '1t27' && !captchaV2) {
              this.recaptchaV2 = true
              return;
            }

            if (redirect_uri) {
              if (e?.response?.status === 401) {
                window.location.href = `${redirect_uri}?temp_token=${e.response.data.token}&social=telegram`
              } else {
                window.location.href = `${redirect_uri}?error=true`
              }
            }
          })
    },

    onClick() {
      let bot_id = process.env.TELEGRAM_BOT_ID

      if (this.debugMode) {
        console.log('bot_id', bot_id)
        console.log('process.env.TELEGRAM_BOT_ID', process.env.TELEGRAM_BOT_ID)
      }

      this.$emit('is-loading', true)
      // eslint-disable-next-line no-undef
      Telegram.Login.auth(
          {bot_id, request_access: true},
          (data) => {
            if (this.debugMode) {
              console.log('data', data)
            }
            if (!data) {
              window.close()
              return
            }

            this.telegramData = data
            this.getSocialData()
          }
      )
    },

    initReCaptcha: function () {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
          this.initReCaptcha()
        }
      }.bind(this), 100);
    }
  },

  async mounted() {
    await this.$loadScript('https://telegram.org/js/telegram-widget.js?16', {
      'data-telegram-login': "OGameSite_bot",
      'data-size': "large",
      'data-request-access': "write",
      'data-userpic': "false"
    }, true)

    await this.$loadScript(`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_ID}`)
    this.initReCaptcha()
  }
}
</script>

<style>
[id^="telegram-login"] {
  display: none !important;
}
</style>

<style lang="scss" scoped>
button.tgme_widget_login_button {
  display: inline-block;
  vertical-align: top;
  font-weight: 500;
  line-height: 20px;
  background-color: #54a9eb;
  text-overflow: ellipsis;
  overflow: hidden;
  border: none;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 16px;
  padding: 9px 21px 11px;
  border-radius: 20px;
}

.tgme_widget_login_button_icon {
  width: 24px;
  height: 22px;
  margin: 0 13px -2px -7px;
  background: url(data:image/svg+xml,%3Csvg%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m1.95617055%2011.392196c5.77764656-2.42328736%209.63031585-4.02086673%2011.55800785-4.79273807%205.5039525-2.20384954%206.6476266-2.5866818%207.3930574-2.59932314.1639507-.00278035.5305319.0363352.7679878.22182361.2005031.15662277.2556695.36819788.2820684.51669348.026399.1484956.0592719.48677234.0331404.75109194-.2982611%203.0169019-1.5888322%2010.33812718-2.2454015%2013.71710898-.2778191%201.4297738-.8288514%201.7357846-1.3584441%201.7826999-1.1509274.1019576-2.0208916-.5588425-3.1356211-1.2622918-1.7443316-1.1007592-2.3854935-1.3972358-4.0786694-2.4713734-1.95675765-1.2413519-.8891962-1.8911034.2259543-3.0061212.2918402-.2918054%205.3989024-4.83750096%205.497052-5.24030969.0122753-.05037796-.1557336-.55407742-.2716182-.65323489-.1158847-.09915747-.2869204-.06524947-.4103446-.03828214-.17495.03822537-2.9615423%201.81132342-8.35977698%205.31929412-.79096496.5228681-1.50739646.7776269-2.1492945.7642766-.70764107-.0147176-2.06885864-.3851791-3.08078398-.7018404-1.24116762-.388398-1.69932554-.5713149-1.61342745-1.2309348.04474105-.3435709.36011227-.7024173.94611366-1.0765391z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E) no-repeat 0 -1px;
  display: inline-block;
  vertical-align: top;
}
</style>